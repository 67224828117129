<template>
  <div>
    <h1>404</h1>
    <p>
      <translate>
        Sorry, the page you are looking for could not be found.
      </translate>
    </p>
  </div>
</template>

<script>
export default {
  name: "404"
}
</script>

<style scoped>

</style>